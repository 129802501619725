import {gql} from 'apollo-boost'

const fragment = `{
    id
    name
    phone
    location
    company{
        name
        id
    }
    createdAt
}
`

export const create = gql`
    mutation createDepot($data: DepotCreateInput!){
        createDepot(data: $data)${fragment}
}
`

export const get = gql`
    query depots($where: DepotWhereInput){
    depots(where: $where)${fragment}
}
`

export const update = gql`
    mutation updateDepot($where: DepotWhereUniqueInput! $data: DepotUpdateInput!){
        updateDepot(where: $where, data: $data)${fragment}
    }
`

export const remove = gql`
    mutation deleteDepot($where: DepotWhereUniqueInput!){
        deleteDepot(where: $where){
            id
        }
    }
`

