<template>
	<div>
		<DataTable
			:getQuery="queries.get"
			:createQuery="queries.create"
			:removeQuery="queries.remove"
			:updateQuery="queries.update"
			:connector="connector"
			:headers="headers"
			:createFields="createFields"
			:where="where"
			icon="mdi-home"
			result_name="depots"
			title="depot"
			subtitle="List of all items"
		></DataTable>
	</div>
</template>
<script>
import DataTable from '@/components/datatable'
export default {
	components: {DataTable},

  created(){
    let id = this.$route.params.id
    if(id){
      this.connector = {
        company: {
          connect: { id }
        }
      }

      this.where = {
        company: { id }
      }
    }
  },
  data: () => ({
    queries: require('../../../../legacy/unwanted_gql/depot.gql'),
    connector: {},
    where: {},
    createFields: [
      {name: 'name', label: 'Name', icon: 'mdi-account'},
      {type: 'tel', name: 'phone', label: 'Phone', icon: 'mdi-phone'},
      {name: 'location', label: 'Location', icon: 'mdi-map-marker'},
    ],
    headers: [
      {text: 'Name', align: 'left', value: 'name'},
      {text: 'Phone No.', align: 'left', value: 'phone'},
      {text: 'Location.', align: 'left', value: 'location'},
      {text: 'Date Added', align: 'left', value: 'createdAt'},
      {text: 'Actions', value: 'action', sortable: false},
    ],
  }),
}
</script>
